.container-fullwidth {
  position: relative;
  max-width: 100vw;
  background: url("./images/portalbg.jpg") no-repeat;
  background-size: cover;
}

.deloitteLogo {
  width: 300px;
  height: 60px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.startButton,
.audioButton {
  width: 160px;
  margin: 10px;
  cursor: url("./cursor/link1.png"), default !important;
}

.greyStart {
  filter: grayscale(1);
}

.roompicker {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 70%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}

.circleImg {
  width: 200px;
}

.circleImgGrey {
  margin-top: 30px;
  width: 140px;
  filter: grayscale(1);
}

.room-title {
  margin-top: 10px;
  text-align: center;
  font-size: 1.6em;
  font-weight: bold;
  padding: 20px;
}

.room-title-grey {
  margin-top: 10px;
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  padding: 20px;
  color: grey;
}

.room {
  cursor: url("./cursor/link1.png"), default !important;
}

.container-maxwidth {
  position: relative;
  max-width: 1280px;
}

.centerText {
  text-align: center;
}

.ip-access {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 0.7em;
  font-weight: 300;
  text-align: center;
}

.title-access {
  margin-bottom: 40px;
  font-size: 2.3em;
  font-weight: 600;
  text-align: center;
}

.label-access {
  margin-bottom: 20px;
  font-size: 1em;
  text-align: center;
}

.input-access {
  display: block;
  margin-right: 10px;
  width: 280px;
  height: 43px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  font-size: 1em;
  border-radius: 16px;
  background-color: rgb(34, 34, 34);
  border: 4px solid rgb(160, 160, 160);
  text-decoration-style: wavy;
}

.form-access-wrapper {
  text-align: left;
}

.form-access {
  margin-top: 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  align-items: center;
}

.section-access {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
