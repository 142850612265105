html,
iframe,
iframe.html,
.vp-center {
  height: 100%;
  cursor: url("./cursor/normal.png"), default !important;
}

.iframeoverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  cursor: url("./cursor/normal.png"), default !important;
}

a,
button,
.button,
.pure-button,
.bottleA,
.bottleB,
.bottleC,
.bottleD,
.radioSpot,
.poemSpot,
.camSpot,
.submitMM,
.mmA,
.mmB,
.mmC,
.mmD {
  cursor: url("./cursor/link1.png"), default !important;
}

input[type="radio"],
label {
  cursor: url("./cursor/link1.png"), default !important;
  width: 17px;
  height: 17px;
  font-size: 1.25em;
}

.roleImg {
  cursor: url("./cursor/move.png"), default !important;
}

input {
  cursor: url("./cursor/text.png"), default !important;
}

body {
  color: #f0f0f0;
  font-size: 1em;
  line-height: 1.6em;
  min-height: 100%;
  margin: 5px 0px 0px 0px;
  background-color: #0b1c2e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.orbitron {
  font-family: Orbitron !imporant;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

html[data-w-dynpage] [data-w-cloak] {
  color: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

blockquote {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 18px;
  line-height: 22px;
}

ul,
ol {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

button,
html input[type="button"],
input[type="reset"] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
}
